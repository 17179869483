'use client';

import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { capitalize } from 'lodash';

import { useAppDispatch } from '@/store';
import { setNotification } from '@/store/feature/common.slice';
import { setUserInfo } from '@/store/feature/user.slice';

import { consultantApi } from '@/app/(profile)/services/api.service';
import { apiConsultants } from '@/services/consultants/api.service';

interface IUseConsultantsQuery {
  page?: number;
  perPage?: number;
  search?: string;
  expertiseSearch?: string[];
  status?: 'active' | 'inactive';
}

const useConsultantsQuery = ({
  page = 1,
  perPage = 10,
  search = '',
  expertiseSearch = [],
  status = 'active',
}: IUseConsultantsQuery) => {
  const key = ['consultants', search, expertiseSearch, page, perPage, status];

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: key,
    queryFn: async () => {
      const { data } = await apiConsultants.getConsultants({
        page,
        search,
        expertiseSearch,
        perPage,
        status,
      });

      if (data) {
        return data;
      }
      return new Error('Something went wrong');
    },
    placeholderData: keepPreviousData,
  });

  const invalidateConsultants = () => {
    queryClient.invalidateQueries({ queryKey: ['consultants'] });
  };

  return { query, invalidateConsultants };
};

export default useConsultantsQuery;

export const useGetConsultantDetail = (id: string) => {
  const query = useQuery({
    queryKey: ['consultants', id],
    queryFn: async () => {
      if (!id) {
        return { data: null };
      }
      const { data } = await apiConsultants.getConsultantDetail(id);
      if (data) {
        return data;
      }
      return new Error('Something went wrong');
    },
    enabled: !!id,
  });

  const queryClient = useQueryClient();

  const invalidateConsultantDetail = () => {
    queryClient.invalidateQueries({
      queryKey: ['consultants', id],
    });
  };

  return {
    query,
    invalidateConsultantDetail,
  };
};

export const useConsultants = {};

export const useConsultantProfileMutation = () => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: ({ id = 'me', data }: { id?: string; data: any }) => {
      return consultantApi.updateConsultantInfo(id, data);
    },
    onError: (error) => {
      dispatch(
        setNotification({
          msgContent: capitalize(error.response?.data?.message) || 'Error',
          type: 'error',
          msgTitle: 'Error',
        })
      );
    },
    onSuccess: (data, { id = 'me' }) => {
      if (id === 'me') {
        const dataUser = data.data;
        dispatch(setUserInfo(dataUser));
      }
      dispatch(
        setNotification({
          msgContent: 'Update Profile successfully',
          type: 'success',
          msgTitle: 'Success',
        })
      );
    },
  });
};
