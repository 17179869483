import React, { useRef } from 'react';
import useResize from './useResize';
const useBodySize = (withDefault = false) => {
  const [bodySizes, setBodySizes] = React.useState({
    width: withDefault ? document.body.clientWidth : 0,
    height: withDefault ? document.body.clientHeight : 0,
  });

  const bodyRef = useRef(document.body);
  useResize(bodyRef, () => {
    setBodySizes({
      width: bodyRef.current.clientWidth,
      height: bodyRef.current.clientHeight,
    });
  });
  return bodySizes;
};

export default useBodySize;
