import { Button, Typography } from 'antd';

import { useAppDispatch } from '@/store';
import { setViewDrawer } from '@/store/feature/common.slice';

import AppCard from './AppCard';
import { ProjectTypeTag } from '../Tag/ProjectType';

import { ITenderInfo } from '@/types/common.type';
const { Text, Title } = Typography;
type CardTenderProp = {
  tender: ITenderInfo;
};
const CardTender = ({ tender }: CardTenderProp) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(
      setViewDrawer({
        visible: true,
        id: tender.id as string,
        type: 'crawl',
        title: '',
      })
    );
  };
  return (
    <div className='cursor-pointer text-textBase' onClick={onClick}>
      <AppCard className=' flex min-h-[320px] flex-col items-start gap-2 '>
        <div className='min-h-14'>
          <Title level={4} className='mb-0 line-clamp-2'>
            {tender.title}
          </Title>
        </div>
        <ProjectTypeTag type={'crawl'} />
        <Text className='line-clamp-5'>{tender.companyInfo}</Text>
        <Button type='primary' className='mt-auto w-full'>
          View Details
        </Button>
      </AppCard>
    </div>
  );
};

export default CardTender;
