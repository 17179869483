import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Carousel, CarouselProps } from 'antd';
import React from 'react';
const NextArrow = ({ onClick }: any) => {
  return (
    <Button
      className='absolute right-0 top-[calc(100%_-_10px)] -translate-x-1/2 xl:-right-14 xl:top-1/2'
      onClick={onClick}
      shape='circle'
      icon={<ArrowRightOutlined />}
    />
  );
};
const PreArrow = ({ onClick }: any) => {
  return (
    <Button
      className='xl-translate-y-1/2 absolute left-[calc(100%_-_110px)] top-[calc(100%_-_10px)] xl:-left-10 xl:top-1/2'
      onClick={onClick}
      shape='circle'
      icon={<ArrowLeftOutlined />}
    />
  );
};

type AppCarouselProp = {
  slideCount?: number;
} & CarouselProps;

const AppCarousel = ({
  children,
  className,
  slideCount = -1,
  ...rest
}: AppCarouselProp) => {
  const initClass =
    '[&_.slick-slide>div]:h-full [&_.slick-slide]:h-[inherit] [&_.slick-track]:flex';
  return (
    <Carousel
      slidesToShow={4}
      arrows={slideCount === -1 || slideCount > 4}
      dots={false}
      nextArrow={<NextArrow />}
      prevArrow={<PreArrow />}
      infinite={false}
      responsive={[
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            className: `${initClass} mb-10 [&_.slick-dots-bottom]:bottom-0 [&_.slick-dots>li>button]:bg-primary [&_.slick-dots>li]:w-1 [&_.slick-dots>li]:h-1 [&_.slick-dots>li]:rounded-full [&_.slick-dots>li>button]:h-full`,
            dots: true,
            arrows: slideCount === -1 || slideCount > 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            className: `${initClass} mb-10 [&_.slick-dots-bottom]:bottom-0 [&_.slick-dots>li>button]:bg-primary [&_.slick-dots>li]:w-1 [&_.slick-dots>li]:h-1 [&_.slick-dots>li]:rounded-full [&_.slick-dots>li>button]:h-full`,
            dots: true,
            arrows: slideCount === -1 || slideCount > 1,
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
            className: `${initClass} mb-10 [&_.slick-dots-bottom]:bottom-0 [&_.slick-dots>li>button]:bg-primary [&_.slick-dots>li]:w-1 [&_.slick-dots>li]:h-1 [&_.slick-dots>li]:rounded-full [&_.slick-dots>li>button]:h-full`,
            dots: true,
            arrows: slideCount === -1 || slideCount > 1,
          },
        },
      ]}
      className={`${initClass} ${className} `}
      {...rest}
    >
      {children}
    </Carousel>
  );
};

export default AppCarousel;
