import { useQuery } from '@tanstack/react-query';

import { homepageApi } from '@/services/homepage/api.service';

export const useHomepage = () => {
  const query = useQuery({
    queryKey: ['homepage'],
    queryFn: async () => {
      const res = await homepageApi.getStatistics();

      return res;
    },
  });

  return { query };
};
