import api from '../api/api.service';
import endpointConfig from '../api/endpoint.config';

import { IMetaQuery } from '@/types/common.type';

const addJob = async (data: any) => {
  return await api.post(endpointConfig.jobs, data);
};
const editJob = async (id: string, data: any) => {
  return await api.put(endpointConfig.jobs + `/${id}`, data);
};
const editJobStatus = async (id: string, status: string) => {
  return await api.put(endpointConfig.jobs + `/${id}/status`, { status });
};
const deleteJob = async (id: string) => {
  return await api.delete(endpointConfig.jobs + `/${id}`);
};
const getJobDetail = async (id: any) => {
  return await api.get(endpointConfig.jobs + `/${id}`);
};
const getAllJob = async (params: any) => {
  return await api.get(endpointConfig.jobs, { params });
};

const saveJob = async ({
  id,
  type,
}: {
  id: string;
  type: 'project' | 'crawl';
}) => {
  return await api.post(endpointConfig.saveJob + `/${type}/${id}`);
};

const discardSavedJob = async ({
  id,
  type,
}: {
  id: string;
  type: 'project' | 'crawl';
}) => {
  return await api.delete(endpointConfig.saveJob + `/${type}/${id}`);
};

const getSavedJobs = async (params: IMetaQuery) => {
  return await api.get(endpointConfig.saveJob, { params });
};
const getApplicationCount = async (id: string) => {
  return await api.get(`${endpointConfig.jobs}/${id}/application-counter`);
};
export const JobApiService = {
  editJobStatus,
  deleteJob,
  addJob,
  editJob,
  getJobDetail,
  getAllJob,
  saveJob,
  discardSavedJob,
  getSavedJobs,
  getApplicationCount,
};
