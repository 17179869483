import React, { ReactNode } from 'react';
type AppCardProp = {
  children: ReactNode;
  className?: string;
  [key: string]: any;
};
const AppCard = ({ children, className = '', ...rest }: AppCardProp) => {
  return (
    <div
      className={` rounded-2xl bg-white p-5 shadow-cardItem transition-shadow hover:shadow-cardItemHover  ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default AppCard;
