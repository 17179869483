/* eslint-disable no-case-declarations */
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { adminApi } from '@/app/(profile)/services/api.service';
import { JobApiService } from '@/services/job/api.service';

export interface ISearchJob {
  type: 'project' | 'tender' | 'ai';
  page?: number;
  perPage?: number;
  search?: string;
  status?: string;
  expertiseSearch?: string[];
  levelOfExperience?: string[];
  location?: string;
}

export const useSearchJob = (
  {
    type = 'project',
    page = 1,
    perPage = 10,
    search,
    status = 'active',
    expertiseSearch,
    levelOfExperience,
    location,
  }: ISearchJob,
  enabled = true
) => {
  const queryClient = useQueryClient();
  const key = [
    'find-job',
    type,
    search,
    status,
    page,
    perPage,
    expertiseSearch?.sort()?.join(),
    levelOfExperience,
    location,
  ];

  const query = useQuery({
    queryKey: key,
    queryFn: async () => {
      switch (type) {
        case 'project':
          const { data } = await JobApiService.getAllJob({
            page,
            status,
            search: search?.trim(),
            perPage,
            expertiseSearch,
            levelOfExperience,
            location,
          });

          if (data) {
            return data;
          }
          return new Error('Something went wrong');

        case 'tender':
          const { data: dataTender } = await adminApi.getAllCrawlJobs({
            page,
            status,
            search: search?.trim(),
            perPage,
            location,
          });

          if (dataTender) {
            return dataTender;
          }
          return new Error('Something went wrong');

        case 'ai':
          const { data: dataByAI } = await adminApi.getBestMatch({
            page,
            status,
            search: search?.trim(),
            perPage,
            location,
            expertiseSearch,
            levelOfExperience,
          });

          if (dataByAI) {
            return dataByAI;
          }

          return new Error('Something went wrong');
      }
    },
    enabled,
    placeholderData: keepPreviousData,
  });

  const invalidateJobs = () => {
    queryClient.invalidateQueries({ queryKey: ['find-job'] });
  };

  return { query, invalidateJobs };
};
