import api from '@/services/api/api.service';
import endpointConfig from '@/services/api/endpoint.config';

import { IMetaQuery } from '@/types/common.type';

//consultant
const getAllConsultant = async ({
  perPage,
  page,
  search,
  status,
  role,
}: {
  perPage?: number;
  page?: number;
  search?: string;
  status?: string;
  role?: string;
}) => {
  return await api.get(endpointConfig.consultants, {
    params: { perPage, page, search, status, role },
  });
};

const getConsultantInfo = async (id: string) => {
  return await api.get(endpointConfig.consultants + `/${id}`);
};

const updateConsultantInfo = async (id: string, data: any) => {
  return await api.put(endpointConfig.consultants + `/${id}`, data);
};

const getExpertises = async ({
  search,
  corporateId,
}: {
  search?: string;
  corporateId?: string | number;
}) => {
  return await api.get(endpointConfig.expertise, {
    params: { search, corporateId },
  });
};

const getPresignedUrl = async (data: { fileName: string }) => {
  return await api.post(endpointConfig.presignedUrl, data);
};

export const consultantApi = {
  getConsultantInfo,
  updateConsultantInfo,
};

export const commonApi = {
  getPresignedUrl,
  getExpertises,
};
//corporates

const getAllCorporates = async ({
  perPage,
  page,
  search,
  status,
}: {
  perPage?: number;
  page?: number;
  search?: string;
  status?: string;
}) => {
  return await api.get(endpointConfig.corporate, {
    params: { perPage, page, search, status },
  });
};

const createCorporate = async (data: any) => {
  return await api.post(endpointConfig.corporate, data);
};
const updateStatusCorporate = async (id: string, status: string) => {
  return await api.put(endpointConfig.corporateStatus.replace('{id}', id), {
    status,
  });
};
//users
const getAllUsers = async ({
  perPage,
  page,
  search,
  status,
  role,
}: {
  perPage?: number;
  page?: number;
  search?: string;
  status?: string;
  role?: string;
}) => {
  return await api.get(endpointConfig.users, {
    params: { perPage, page, search, status, role },
  });
};
const createUser = async ({
  email,
  name,
  role,
  corporateId,
}: {
  email: string;
  name: string;
  role: string;
  corporateId?: string;
}) => {
  return await api.post(endpointConfig.users, {
    email,
    name,
    role,
    corporateId,
  });
};
const updateUserStatus = async (id: string, status: string) => {
  return await api.put(
    endpointConfig.usersStatus.replace('{id}', id?.toString()),
    {
      status,
    }
  );
};
const updateUserInfo = async (id: string, data: any) => {
  return await api.put(endpointConfig.users + `/${id}`, data);
};

//application
const getAllApplications = async ({
  perPage,
  page,
  search,
  status,
  corporateId,
  consultantId,
}: {
  perPage?: number;
  page?: number;
  search?: string;
  status?: string;
  corporateId?: string;
  consultantId?: string;
}) => {
  return await api.get(endpointConfig.applications, {
    params: { perPage, page, search, status, consultantId, corporateId },
  });
};

//jobs
const getAllJobs = async ({
  perPage,
  page,
  search,
  status,
  corporateId,
}: {
  perPage?: number;
  page?: number;
  search?: string;
  status?: string;
  corporateId?: string;
}) => {
  return await api.get(endpointConfig.jobs, {
    params: { perPage, page, search, status, corporateId },
  });
};
const updateJobStatus = async (id: string, status: string) => {
  return await api.put(endpointConfig.jobs + `/${id}/status`, {
    status,
  });
};
const deleteJob = async (id: string) => {
  return await api.delete(endpointConfig.jobs + `/${id}`);
};

//crawl job
const getAllCrawlJobs = async ({
  perPage,
  page,
  search,
  status,
  deadlineFrom,
  deadlineTo,
  location,
}: {
  perPage?: number;
  page?: number;
  search?: string;
  status?: string;
  deadlineFrom?: string;
  deadlineTo?: string;
  location?: string;
}) => {
  return await api.get(endpointConfig.crawlJob, {
    params: {
      perPage,
      page,
      search,
      status,
      deadlineFrom,
      deadlineTo,
      location,
    },
  });
};
const exportCrawlJobs = async ({
  search,
  status,
  deadlineFrom,
  deadlineTo,
  timezone,
}: {
  search?: string;
  status?: string;
  deadlineFrom?: string;
  deadlineTo?: string;
  timezone: string;
}) => {
  return await api.get(endpointConfig.crawlJob + '/export', {
    params: { search, status, deadlineFrom, deadlineTo, timezone },
    responseType: 'blob',
  });
};
const deleteCrawlJob = async (id: string) => {
  return await api.delete(endpointConfig.crawlJob + `/${id}`);
};
const getCrawlJob = async (id: string) => {
  return await api.get(endpointConfig.crawlJob + `/${id}`);
};
const updateCrawlJob = async (id: string, data: any) => {
  return await api.put(endpointConfig.crawlJob + `/${id}`, data);
};
const updateCrawlJobStatus = async (id: string, status: string) => {
  return await api.put(endpointConfig.crawlJob + `/${id}/status`, {
    status,
  });
};

const getBestMatch = async (data: IMetaQuery) => {
  return await api.get(endpointConfig.jobs + '/best-matches', {
    params: data,
  });
};

export const adminApi = {
  getAllUsers,
  createUser,
  getAllCorporates,
  getConsultantInfo,
  getAllConsultant,
  updateUserStatus,
  updateUserInfo,
  createCorporate,
  updateStatusCorporate,
  getAllApplications,
  getAllJobs,
  updateJobStatus,
  deleteJob,
  getAllCrawlJobs,
  exportCrawlJobs,
  deleteCrawlJob,
  getCrawlJob,
  updateCrawlJob,
  updateCrawlJobStatus,
  getBestMatch,
};
