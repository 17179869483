import api from '../api/api.service';
import endpointConfig from '../api/endpoint.config';

const getStatistics = async () => {
  try {
    const response = await api.get(endpointConfig.homepage);
    if (response) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};

export const homepageApi = {
  getStatistics,
};
