import { Tag } from 'antd';
import React from 'react';

interface IProjectTypeTag {
  type: 'project' | 'crawl';
}

const colorsMapping = {
  project: '#87D068',
  crawl: '#108EE9',
};

export const ProjectTypeTag: React.FC<IProjectTypeTag> = ({ type }) => {
  return (
    <Tag
      color={colorsMapping[type]}
      className='min-w-[75px] text-center capitalize'
    >
      {type === 'project' ? 'Project' : 'Tender'}
    </Tag>
  );
};
