'use client';

import { Flex, Tabs } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';
import { MdCorporateFare } from 'react-icons/md';
import { PiBriefcaseDuotone, PiUsersDuotone } from 'react-icons/pi';

import { useAppSelector } from '@/store';

import Working from '@/assets/webp/searching-talent.webp';
import { Routes, TypeFindJob } from '@/helpers/common.constant';
import { useHomepage } from '@/queries/useHomepage';

import ConsultantCarousel from './(profile)/components/Carousels/Consultant';
import JobCarousel from './(profile)/components/Carousels/Job';

export default function Home() {
  const router = useRouter();

  const { auth } = useAppSelector((state) => state.auth);

  const {
    query: { data },
  }: any = useHomepage();

  React.useEffect(() => {
    if (auth) {
      router.push(Routes._OVERVIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const [tab, setTab] = useState(TypeFindJob._TENDER);

  return (
    <div className='flex  w-full flex-col items-center justify-center bg-white px-5 pb-20 '>
      <div className='mb-8 flex w-full flex-col gap-5 xl:flex-row 2xl:pt-16'>
        <div className='flex w-full  flex-col gap-8 xl:w-3/5'>
          <span className='text-4xl font-medium leading-[64px] text-textBase'>
            Join us
          </span>

          <div className=''>
            <p className='text-left text-lg'>
              Welcome to ESG Lance: Bridging Corporations and ESG Consultants
              <br />
              In today&apos;s rapidly evolving business landscape, corporations
              are increasingly recognizing the importance of integrating
              Environmental, Social, and Governance (ESG) principles into the
              core strategies. However, finding the right expertise to drive
              these initiatives can be challenging. That&apos;s where ESG lance
              comes in.
            </p>
          </div>

          <div className='flex flex-col gap-3 lg:flex-row'>
            <div className='flex w-full flex-row gap-5 rounded-lg bg-white p-5 lg:w-1/3 xl:w-80'>
              <PiBriefcaseDuotone
                size={72}
                className='rounded-lg bg-primary bg-opacity-10 p-4 text-primary'
              />
              <div className='flex flex-col justify-center gap-4'>
                <span className='text-2xl font-medium'>
                  + {data?.noOfOpenJob || 0}
                </span>
                <span className='text-base text-[#767f8c]'>Active Jobs</span>
              </div>
            </div>
            <div className='flex w-full flex-row gap-5 rounded-lg bg-white p-5 shadow-main lg:w-1/3 xl:w-80'>
              <MdCorporateFare
                size={72}
                className='rounded-lg bg-primary  p-4 text-white'
              />
              <div className='flex flex-col justify-center gap-4'>
                <span className='text-2xl font-medium'>
                  + {data?.noOfCorporate || 0}
                </span>
                <span className='text-base text-[#767f8c]'>Organizations</span>
              </div>
            </div>
            <div className='flex w-full flex-row gap-5 rounded-lg bg-white p-5 lg:w-1/3 xl:w-80'>
              <PiUsersDuotone
                size={72}
                className='rounded-lg bg-primary bg-opacity-10 p-4 text-primary'
              />

              <div className='flex flex-col justify-center gap-4'>
                <span className='text-2xl font-medium'>
                  + {data?.noOfConsultant || 0}
                </span>
                <span className='text-base text-[#767f8c]'>Consultants</span>
              </div>
            </div>
          </div>
        </div>
        <div className='flex w-full items-center justify-center xl:w-2/5'>
          <Image src={Working} alt='Working' width={420} height={320} />
        </div>
      </div>
      <div className='w-full'>
        <Flex align='center' justify='space-between' className=' font-medium'>
          <h2 className='text-2xl'>Jobs</h2>
          <Link
            className='text-textBase hover:text-primary hover:underline'
            href={`${Routes._FIND_WORK}?typeSearch=${tab}`}
          >
            View More
          </Link>
        </Flex>
        <div className=''>
          <Tabs
            activeKey={tab}
            onChange={(t: any) => setTab(t)}
            items={[
              { label: 'Tenders', key: TypeFindJob._TENDER },
              { label: 'Projects', key: TypeFindJob._PROJECT },
            ]}
          />
        </div>
      </div>
      <div className={`w-full`}>
        <div className='-mx-4'>
          <JobCarousel type={tab} key={tab} />
        </div>
      </div>
      <div className={`w-full `}>
        <Flex align='center' justify='space-between' className=' font-medium'>
          <h2 className='text-2xl'>Consultants</h2>
          <Link
            className='text-textBase hover:text-primary hover:underline'
            href={Routes._FIND_CONSULTANT}
          >
            View More
          </Link>
        </Flex>
        <div className='-mx-4 '>
          <ConsultantCarousel />
        </div>
      </div>
    </div>
  );
}
