import { Button, Typography } from 'antd';

import { useAppDispatch } from '@/store';
import { setViewDrawer } from '@/store/feature/common.slice';

import AppCard from './AppCard';
import { ProjectTypeTag } from '../Tag/ProjectType';

import { IJob } from '@/types/common.type';
const { Text, Title } = Typography;
type CardJobProp = {
  job: IJob;
};
const CardJob = ({ job }: CardJobProp) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(
      setViewDrawer({
        visible: true,
        id: job.id as string,
        type: 'project',
        title: '',
      })
    );
  };
  return (
    <div className='cursor-pointer text-textBase' onClick={onClick}>
      <AppCard className=' flex min-h-[320px] flex-col items-start gap-2 '>
        <div className='min-h-14'>
          <Title level={4} className='mb-0 line-clamp-2'>
            {job.title}
          </Title>
        </div>
        <ProjectTypeTag type={'project'} />
        <Text className='line-clamp-5'>{job.description}</Text>
        <Button type='primary' className='mt-auto w-full'>
          View Details
        </Button>
      </AppCard>
    </div>
  );
};

export default CardJob;
