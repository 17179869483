import { Flex, Tag, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';

import useResize from '@/hooks/useResize';

import { IExpertise } from '@/types/common.type';
type ExpertiseTagProp = {
  items?: IExpertise[];
  maxLine?: number;
};
const ExpertiseListTag = ({ items = [], maxLine }: ExpertiseTagProp) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const countRef = useRef<HTMLSpanElement | null>(null);
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);

  useResize(containerRef, () => {
    if (containerRef.current) {
      const countElement = countRef.current;
      countElement?.classList.add('invisible');
      const childItems = containerRef.current.querySelectorAll(
        '*:not(:last-child)'
      ) as NodeListOf<HTMLSpanElement>;
      const parentHeight = containerRef.current.offsetHeight;
      const hideList: any[] = [];

      childItems.forEach((item) => {
        item.classList.remove('hidden');
        if (item.offsetTop > parentHeight) {
          item.classList.add('hidden');
          hideList.push(item.innerText);
        }
      });

      while (countElement && countElement.offsetTop > parentHeight) {
        const showing = containerRef.current.querySelectorAll(
          '*:not(:last-child):not(.hidden)'
        ) as NodeListOf<HTMLSpanElement>;
        const lastItem = showing[showing.length - 1];
        lastItem.classList.add('hidden');
        hideList.push(lastItem.innerText);
      }

      if (hideList.length) {
        countElement?.classList.remove('invisible');
      }
      setHiddenItems(hideList);
    }
  });
  const sorted = items.toSorted((a, b) => a.name.length - b.name.length);
  const className =
    'text-[#676767]  mr-0 min-w-[40px] rounded-[160px] border-transparent border bg-[#e9e9e9] text-center text-sm leading-[30px] ';
  return (
    <Flex
      gap={5}
      wrap
      className='relative w-full overflow-hidden'
      ref={containerRef}
      style={{
        maxHeight: maxLine ? 32 * maxLine + 5 * (maxLine - 1) : undefined,
      }}
    >
      {sorted.map((item) => (
        <Tag className={`${className} px-3`} key={item.id}>
          {item.name}
        </Tag>
      ))}
      <Tooltip
        title={
          <ul>
            {hiddenItems.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        }
      >
        <Tag ref={countRef} className={`${className} order-1`}>
          +{hiddenItems.length}
        </Tag>
      </Tooltip>
    </Flex>
  );
};

export default ExpertiseListTag;
