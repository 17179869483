import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

const useResize = (targetRef: any, callback: any, dependencies: any[] = []) => {
  const currentDependenciesRef = useRef(dependencies);

  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }
  useEffect(() => {
    if (targetRef?.current) {
      const observer = new ResizeObserver(callback);
      observer.observe(targetRef.current);
      return () => {
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef, currentDependenciesRef.current]);
};

export default useResize;
