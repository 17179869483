import { Flex } from 'antd';
import React from 'react';

import CardConsultant from '@/components/Card/Consultant';
import AppCarousel from '@/components/Carousel';

import useConsultantsQuery from '@/queries/useConsultants';

const ConsultantCarousel = () => {
  const {
    query: { data: { data } = {} },
  } = useConsultantsQuery({});
  if (!data?.length) {
    return (
      <Flex className='h-[355px]' justify='center' align='center' vertical>
        No Consultant Available
      </Flex>
    );
  }
  return (
    <AppCarousel slideCount={data?.length}>
      {data?.map((p: any) => (
        <div key={p.id} className='h-full p-4'>
          <CardConsultant consultant={p} />
        </div>
      ))}
    </AppCarousel>
  );
};

export default ConsultantCarousel;
