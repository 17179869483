import api from '../api/api.service';
import endpointConfig from '../api/endpoint.config';

const getConsultants = async (params: any) => {
  return await api.get(endpointConfig.consultants, { params });
};

const getConsultantDetail = async (id: number | string) => {
  return await api.get(`${endpointConfig.consultants}/${id}`);
};

export const apiConsultants = {
  getConsultants,
  getConsultantDetail,
};
