'use client';
import { Avatar, Button, Flex, Typography } from 'antd';

import { useAppDispatch } from '@/store';
import { setViewDrawer } from '@/store/feature/common.slice';

import { BREAK_POINTS } from '@/helpers/common.constant';
import { generateAvatar } from '@/helpers/utils';

import AppCard from './AppCard';
import ExpertiseListTag from '../Tag/ExpertiseList';

import { IConsultant } from '@/types/common.type';
import useBodySize from '@/hooks/useBodySize';
const { Text, Title } = Typography;
type CardConsultantProp = {
  consultant: IConsultant;
};
const CardConsultant = ({ consultant }: CardConsultantProp) => {
  const dispatch = useAppDispatch();
  const { width } = useBodySize();

  const onClick = () => {
    dispatch(
      setViewDrawer({
        visible: true,
        id: consultant.id as string,
        type: 'consultant',
        title: '',
      })
    );
  };
  return (
    <AppCard
      className=' flex h-full min-h-[320px] cursor-pointer flex-col items-start gap-2 text-textBase '
      onClick={onClick}
    >
      <Flex gap={20}>
        <Avatar
          className='shrink-0'
          size={60}
          src={
            consultant?.avatar
              ? consultant?.avatar
              : generateAvatar(consultant?.name)
          }
        />
        <div className='min-h-14'>
          <Title level={4} className='mb-0 line-clamp-2'>
            {consultant.name}
          </Title>
        </div>
      </Flex>
      <Text className='line-clamp-4 xl:line-clamp-5'>
        {consultant.titleExpertises?.role}
      </Text>
      <ExpertiseListTag
        items={consultant.titleExpertises?.expertises}
        maxLine={width < BREAK_POINTS.XL ? 2 : 3}
      />
      <Button type='primary' className='mt-auto w-full'>
        View Details
      </Button>
    </AppCard>
  );
};

export default CardConsultant;
